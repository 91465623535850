import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet";

const Contact = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);

  const [clientName, setclientName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [messages, setMessages] = useState("");

  // ========== Error Messages Start here ============
  const [errClientName, setErrClientName] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errMobile, setErrMobile] = useState("");
  const [errMessages, setErrMessages] = useState("");
  // ========== Error Messages End here ==============
  const [successMsg, setSuccessMsg] = useState("");

  const handleName = (e) => {
    setclientName(e.target.value);
    setErrClientName("");
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };
  const handleMobile = (e) => {
    setMobile(e.target.value);
    setErrMobile("");
  };
  const handleMessages = (e) => {
    setMessages(e.target.value);
    setErrMessages("");
  };

  // ================= Email Validation start here =============
  const EmailValidation = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  };
  // ================= Email Validation End here ===============

  // ================= Mobile Validation start here =============
  const MobileValidation = (mobile) => {
    return String(mobile)
      .toLowerCase()
      .match(/^[0-9]*$/);
  };
  // ================= Mobile Validation End here ===============

  const handlePost = async(e) => {
    e.preventDefault();
    if (!clientName) {
      setErrClientName("Enter your Name");
    }
    if (!email) {
      setErrEmail("Enter your Email");
    } else {
      if (!EmailValidation(email)) {
        setErrEmail("Enter a Valid Email");
      }
    }
    if (!mobile) {
      setErrMobile("Enter your Mobile No.");
    }   else {
      if (!MobileValidation(mobile)) {
        setErrMobile("Enter a Valid No.");
      }
    }

    if (!messages) {
      setErrMessages("Enter your Messages");
    }
    if (clientName && email && EmailValidation(email) && MobileValidation(mobile) && messages) {


      // fetch("http://localhost:5000/send", {
      //   method: "post",
      //   headers: {
      //     "Content-Type": "application/json"
      //   },
      //   body: JSON.stringify({
      //     clientName,
      //     email,
      //     messages
      //   })
      // }).then(res => res.json())
      //   .then(data => {
      //     setSuccessMsg(
      //       `Thank you dear ${clientName}, Your messages has been received successfully. Futher details will sent to you by your email at ${email}.`
      //     );
      //     toast.success("Data Send Successfully");
      //     console.log("Data Send Successfully");
      //     // alert(data.messages)
      //   }).catch(err => {
      //     console.log(err)
      //     toast.error("Failed to send data");
      //   })





      try {

        const response = await fetch("https://portfolio.inkhire.in/form/contact", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({clientName, email,mobile, messages}),
        });

        if (response.ok) {
          toast.success("Data Send Successfully");
          setSuccessMsg(
            `Thank you dear ${clientName}, Your messages has been received successfully. Futher details will sent to you by your email at ${email}.`
          );
        
        } else {
          
          toast.error("Failed to send data");
        }

      } catch (err) {
        console.log("contact--error",err);
      }
      
      
    }
  };

  return (
    <div className="max-w-container mx-auto px-4">
      
      <Helmet>
        <title>Contact-Inkhire Electrographics</title>
        <meta name="keywords" content="Contact Inkhire Electrographics, inkhire contact number,best electronic manufacturer contact number" />
        <meta name="description" content="Contact Inkhire Electrographics by Email- inkhireelectro@gmail.com or contact number - 0120-4010510, 9958679466" />
        <meta name="description" content="Address - G-312, Sector-63, Hcl Noida, Noida, Gautam Buddha Nagar-201301, Uttar Pradesh, India." />
        <meta name="description" content="Contact - 0120-4010510, 9958679466" />
        <meta name="description" content="Email - inkhireelectro@gmail.com" />
        <meta name="description" content="Email - sales@inkhire.in" />
        

      </Helmet>

      <Breadcrumbs title="Contact" prevLocation={prevLocation} />
      {successMsg ? (
        <p className="pb-20 w-96 font-medium text-green-500">{successMsg}</p>
      ) : (
        <form className="pb-20">
          <h1 className="font-titleFont font-semibold text-3xl">
            Fill up a Form
          </h1>
          <div className="w-[500px] h-auto py-6 flex flex-col gap-6">
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                Name
              </p>
              <input
                onChange={handleName}
                value={clientName}
                name= "name"
                className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="text"
                placeholder="Enter your name here"
              />
              {errClientName && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errClientName}
                </p>
              )}
              </div>
              {/* Email id*/}
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                Email
              </p>
              <input
                onChange={handleEmail}
                value={email}
                name= "email"
                className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="email"
                placeholder="Enter Your Email"
              />
              {errEmail && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errEmail}
                </p>
              )}
              </div>
              {/* Contact number */}
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                Contact
              </p>
              <input
                onChange={handleMobile}
                value={mobile}
                name= "mobile"
                className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="text"
                placeholder="Enter Your Number"
              />
              {errMobile && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errMobile}
                </p>
              )}
              </div>
              {/* Message */}
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                Messages
              </p>
              <textarea
                onChange={handleMessages}
                value={messages}
                name= "messages"
                cols="30"
                rows="3"
                className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor resize-none"
                type="text"
                placeholder="Enter your name here"
              ></textarea>
              {errMessages && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errMessages}
                </p>
              )}
            </div>
            <button
              onClick={handlePost}
              className="w-44 bg-primeColor text-gray-200 h-10 font-titleFont text-base tracking-wide font-semibold hover:bg-black hover:text-white duration-200"
            >
              Send Now
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default Contact;
