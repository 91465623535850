import React from "react";
import Banner from "../../components/Banner/Banner";
import BannerBottom from "../../components/Banner/BannerBottom";
import BestSellers from "../../components/home/BestSellers/BestSellers";
import NewArrivals from "../../components/home/NewArrivals/NewArrivals";
import Sale from "../../components/home/Sale/Sale";
import YearProduct from "../../components/home/YearProduct/YearProduct";
import HomeProduct from "../Shop/HomeProduct";
import TrendingProducts from "../../components/home/TrendingProducts/TrendingProducts";
import { Helmet } from "react-helmet";

const Home = () => {

  return (
    <div className="w-full mx-auto">
      <Helmet>
        <title>Home-Inkhire Electrographics</title>
        <meta name="keywords" content="inkhire,,light, led lamp, buy led bulb, surface light,cob light, down light, panel light,deep juction light, street light, track light" />

      </Helmet>

      <Banner />
      <BannerBottom />
      <div className="max-w-container mx-auto px-4">
        <Sale />
        <NewArrivals />
        <BestSellers />
        <HomeProduct />
        <YearProduct />
        <TrendingProducts />
      </div>
    </div>
  );
};

export default Home;
