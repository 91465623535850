import {

  imprimante1,
  imprimante2,
  imprimante4,
  imprimante5,
  imprimante6,
  imprimante7,
  imprimante8,
  imprimante9,
  encre1,
  encre2,
  encre3,
  encre4,
  ruban1,
  ruban2,
  ruban3,
  ruban4,
  bac1,
  bac2,
  bac3,
  bac4,
  pdf1,
  IE202210209,
  IE202210212,
  IE202210220,
  IE202210240,
  IE202210250,
  RDIE2022301,
  SQIE2022301,
  SQ2x2IE2022301,
  IE202210215,
  IE202210230,
  IE202250207,
  IE202250305,
  IE202250503,
  IE202280410,
  IE202280210,
  IE202280710,
  IE202270150,
  IE202260150,

} from "../assets/images/index";

import "./style.css";

// =================== NavBarList Start here ====================
export const navBarList = [
  {
    _id: 1001,
    title: "Home",
    link: "/",
  },
  {
    _id: 1002,
    title: "Shop",
    link: "/shop",
  },
  {
    _id: 1003,
    title: "About",
    link: "/about",
  },
  {
    _id: 1004,
    title: "Contact",
    link: "contact",
  },
  {
    _id: 1005,
    title: "Blog",
    link: "/blog",
  },
];
// =================== NavBarList End here ======================
// =================== Special Offer data Start here ============
export const SplOfferData = [
  {
    _id: "201",
    img: imprimante1,
    productName: "imprimante",
    price: "35.00",
    color: "Blank and White",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "imprimante",
  },
  {
    _id: "202",
    img: imprimante2,
    productName: "imprimante",
    price: "180.00",
    color: "Gray",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "imprimante",
  },
  {
    _id: "203",
    img: imprimante4,
    productName: "imprimante",
    price: "25.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "imprimante",
  },
  {
    _id: "204",
    img: imprimante5,
    productName: "imprimante",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "imprimante",
  },
  {
    _id: "205",
    img: imprimante6,
    productName: "imprimante",
    price: "25.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "imprimante",
  },
  {
    _id: "206",
    img: imprimante7,
    productName: "imprimante",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "imprimante",
  },
  {
    _id: "207",
    img: imprimante8,
    productName: "imprimante",
    price: "25.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "imprimante",
  },
  {
    _id: "208",
    img: imprimante9,
    productName: "imprimante",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "imprimante",
  },
  {
    _id: "209",
    img: encre1,
    productName: "encre",
    price: "25.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "ancre",
  },
  {
    _id: "210",
    img: encre2,
    productName: "encre",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "ancre",
  },
  {
    _id: "211",
    img: encre3,
    productName: "encre",
    price: "25.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "ancre",
  },
  {
    _id: "212",
    img: encre4,
    productName: "encre",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "ancre",
  },
  {
    _id: "213",
    img: ruban1,
    productName: "Ruban",
    price: "25.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "Ruban",
  },
  {
    _id: "214",
    img: ruban2,
    productName: "Ruban",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "Ruban",
  },
  {
    _id: "215",
    img: ruban3,
    productName: "Ruban",
    price: "25.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "Ruban",
  },
  {
    _id: "216",
    img: ruban4,
    productName: "Ruban",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "Ruban",
  },
  {
    _id: "217",
    img: bac1,
    productName: "Bac de dechet",
    price: "25.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "Bac",
  },
  {
    _id: "219",
    img: bac2,
    productName: "Bac de dechet",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "Bac",
  },
  {
    _id: "220",
    img: bac3,
    productName: "Bac de dechet",
    price: "25.00",
    color: "Mixed",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "Bac",
  },
  {
    _id: "221",
    img: bac4,
    productName: "Bac de dechet",
    price: "220.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
    cat: "Bac",
  },
];
// =================== Special Offer data End here ==============

// =================== PaginationItems Start here ===============

export const paginationItems = [
  {
    _id: "201",
    img: IE202210209,
    productName: "9 Watt LED Bulb",
    price: "30.00",
    color: "White",
    badge: true,
    brand: "OEM",
    des: "High Output Efficiency - The High Glo LED Bulb offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly bulb that is made without lead and mercury.",
    cat: "LED Lamp",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "9 watt High Beam LED Bulb" },
      { label: "Item No.", value: "IE202210209" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "4KV" },
      { label: "Ingress Protection", value: "IP20" },
      { label: "Cap Type", value: "B22/E27" },
      { label: "Housing Type", value: "Aluminiun insert with PC Housing" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },
  {
    _id: "202",
    img: IE202210212,
    productName: "12 Watt LED Bulb",
    price: "50.00",
    color: "White",
    badge: true,
    brand: "OEM",
    des: "High Output Efficiency - The High Glo LED Bulb offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly bulb that is made without lead and mercury.",
    cat: "LED Lamp",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "12 watt High Beam LED Bulb" },
      { label: "Item No.", value: "IE202210212" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "4KV" },
      { label: "Ingress Protection", value: "IP20" },
      { label: "Cap Type", value: "B22/E27" },
      { label: "Housing Type", value: "Aluminiun insert with PC Housing" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },

  {
    _id: "203",
    img: IE202210215,
    productName: "15 Watt LED Bulb",
    price: "65.00",
    color: "White",
    badge: true,
    brand: "OEM",
    des: "High Output Efficiency - The High Glo LED Bulb offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly bulb that is made without lead and mercury.",
    cat: "LED Lamp",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "15 watt High Beam LED Bulb" },
      { label: "Item No.", value: "IE202210215" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "4KV" },
      { label: "Ingress Protection", value: "IP20" },
      { label: "Cap Type", value: "B22/E27" },
      { label: "Housing Type", value: "Aluminiun insert with PC Housing" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },
  {
    _id: "2005",
    img: IE202210220,
    productName: "20 Watt LED Bulb",
    price: "95.00",
    color: "White",
    badge: true,
    brand: "OEM",
    des: "High Output Efficiency - The High Glo LED Bulb offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly bulb that is made without lead and mercury.",
    cat: "LED Lamp",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "20 watt High Beam LED Bulb" },
      { label: "Item No.", value: "IE202210220" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "4KV" },
      { label: "Ingress Protection", value: "IP20" },
      { label: "Cap Type", value: "B22/E27" },
      { label: "Housing Type", value: "Aluminiun insert with PC Housing" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },

  {
    _id: "20005",
    img: IE202210230,
    productName: "30 Watt LED Bulb",
    price: "160.00",
    color: "White",
    badge: true,
    brand: "OEM",
    des: "High Output Efficiency - The High Glo LED Bulb offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly bulb that is made without lead and mercury.",
    cat: "LED Lamp",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "30 watt High Beam LED Bulb" },
      { label: "Item No.", value: "IE202210230" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "4KV" },
      { label: "Ingress Protection", value: "IP20" },
      { label: "Cap Type", value: "B22/E27" },
      { label: "Housing Type", value: "Aluminiun insert with PC Housing" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },

  {
    _id: "206",
    img: IE202210240,
    productName: "40 Watt LED Bulb",
    price: "210.00",
    color: "White",
    badge: true,
    brand: "OEM",
    des: "High Output Efficiency - The High Glo LED Bulb offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly bulb that is made without lead and mercury.",
    cat: "LED Lamp",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "40 watt High Beam LED Bulb" },
      { label: "Item No.", value: "IE202210240" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "4KV" },
      { label: "Ingress Protection", value: "IP20" },
      { label: "Cap Type", value: "B22/E27" },
      { label: "Housing Type", value: "Aluminiun insert with PC Housing" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },

  {
    _id: "207",
    img: IE202210250,
    productName: "50 Watt LED Bulb",
    price: "290.00",
    color: "White",
    badge: true,
    brand: "OEM",
    des: "High Output Efficiency - The High Glo LED Bulb offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly bulb that is made without lead and mercury.",
    cat: "LED Lamp",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "50 watt High Beam LED Bulb" },
      { label: "Item No.", value: "IE202210250" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "4KV" },
      { label: "Ingress Protection", value: "IP20" },
      { label: "Cap Type", value: "B22/E27" },
      { label: "Housing Type", value: "Aluminiun insert with PC Housing" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },

  // ----------Down Light Category---------

  {
    _id: "208",
    img: RDIE2022301,
    productName: "3 Watt LED Panel Light(RD) ",
    price: "67.00",
    color: "White",
    badge: true,
    brand: "OEM",
    des: "High Output Efficiency - The High Glo Panel Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Panel Light that is made without lead and mercury.",
    cat: "Down Light",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "3 watt LED Panel Light (RD)" },
      { label: "Item No.", value: "IE202230103RD" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "4KV" },
      { label: "Ingress Protection", value: "IP20" },
      { label: "Dimension", value: "82x35x82 mm" },
      { label: "Cut Size", value: '3.5"' },
      { label: "Housing Type", value: "Aluminiun with PC Housing" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },
  {
    _id: "233",
    img: RDIE2022301,
    productName: "10 Watt LED Panel Light(RD) ",
    price: "100.00",
    color: "White",
    badge: true,
    brand: "OEM",
    des: "High Output Efficiency - The High Glo Panel Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Panel Light that is made without lead and mercury.",
    cat: "Down Light",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "10 watt LED Panel Light (RD)" },
      { label: "Item No.", value: "IE202230110RD" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "4KV" },
      { label: "Ingress Protection", value: "IP20" },
      { label: "Dimension", value: "110x35x110 mm" },
      { label: "Cut Size", value: '4"' },
      { label: "Housing Type", value: "Aluminiun with PC Housing" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },
  {
    _id: "220",
    img: RDIE2022301,
    productName: "12 Watt LED Panel Light(RD) ",
    price: "166.00",
    color: "White",
    badge: true,
    brand: "OEM",
    des: "High Output Efficiency - The High Glo Panel Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Panel Light that is made without lead and mercury.",
    cat: "Down Light",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "12 watt LED Panel Light (RD)" },
      { label: "Item No.", value: "IE202230112RD" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "4KV" },
      { label: "Ingress Protection", value: "IP20" },
      { label: "Dimension", value: "116x35x116 mm" },
      { label: "Cut Size", value: '6"' },
      { label: "Housing Type", value: "Aluminiun with PC Housing" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },
  {
    _id: "221",
    img: RDIE2022301,
    productName: "15 Watt LED Panel Light(RD) ",
    price: "190.00",
    color: "White",
    badge: true,
    brand: "OEM",
    des: "High Output Efficiency - The High Glo Panel Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Panel Light that is made without lead and mercury.",
    cat: "Down Light",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "15 watt LED Panel Light (RD)" },
      { label: "Item No.", value: "IE202230115RD" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "4KV" },
      { label: "Ingress Protection", value: "IP20" },
      { label: "Dimension", value: "116x35x116 mm" },
      { label: "Cut Size", value: '6"' },
      { label: "Housing Type", value: "Aluminiun with PC Housing" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },
  {
    _id: "215",
    img: RDIE2022301,
    productName: "18 Watt LED Panel Light(RD) ",
    price: "272.00",
    color: "White",
    badge: true,
    brand: "OEM",
    des: "High Output Efficiency - The High Glo Panel Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Panel Light that is made without lead and mercury.",
    cat: "Down Light",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "18 watt LED Panel Light (RD)" },
      { label: "Item No.", value: "IE202230118RD" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "4KV" },
      { label: "Ingress Protection", value: "IP20" },
      { label: "Dimension", value: "220x35x220 mm" },
      { label: "Cut Size", value: '8"' },
      { label: "Housing Type", value: "Aluminiun with PC Housing" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },
  {
    _id: "216",
    img: SQIE2022301,
    productName: "3 Watt LED Panel Light(SQ) ",
    price: "67.00",
    color: "White",
    badge: true,
    brand: "OEM",
    des: "High Output Efficiency - The High Glo Panel Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Panel Light that is made without lead and mercury.",
    cat: "Down Light",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "3 watt LED Panel Light(SQ)" },
      { label: "Item No.", value: "IE202230103SQ" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "4KV" },
      { label: "Ingress Protection", value: "IP20" },
      { label: "Dimension", value: "82x35x82 mm" },
      { label: "Cut Size", value: '3.5"' },
      { label: "Housing Type", value: "Aluminiun with PC Housing" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },
  {
    _id: "209",
    img: SQIE2022301,
    productName: "10 Watt LED Panel Light(SQ) ",
    price: "100.00",
    color: "White",
    badge: true,
    brand: "OEM",
    des: "High Output Efficiency - The High Glo Panel Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Panel Light that is made without lead and mercury.",
    cat: "Down Light",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "10 watt LED Panel Light(SQ)" },
      { label: "Item No.", value: "IE202230110SQ" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "4KV" },
      { label: "Ingress Protection", value: "IP20" },
      { label: "Dimension", value: "110x35x110 mm" },
      { label: "Cut Size", value: '4"' },
      { label: "Housing Type", value: "Aluminiun with PC Housing" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },
  {
    _id: "210",
    img: SQIE2022301,
    productName: "12 Watt LED Panel Light(SQ) ",
    price: "166.00",
    color: "White",
    badge: true,
    brand: "OEM",
    des: "High Output Efficiency - The High Glo Panel Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Panel Light that is made without lead and mercury.",
    cat: "Down Light",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "12 watt LED Panel Light (SQ)" },
      { label: "Item No.", value: "IE202230112SQ" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "4KV" },
      { label: "Ingress Protection", value: "IP20" },
      { label: "Dimension", value: "116x35x116 mm" },
      { label: "Cut Size", value: '6"' },
      { label: "Housing Type", value: "Aluminiun with PC Housing" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },

  {
    _id: "211",
    img: SQIE2022301,
    productName: "15 Watt LED Panel Light(SQ) ",
    price: "190.00",
    color: "White",
    badge: true,
    brand: "OEM",
    des: "High Output Efficiency - The High Glo Panel Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Panel Light that is made without lead and mercury.",
    cat: "Down Light",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "15 watt LED Panel Light (SQ)" },
      { label: "Item No.", value: "IE202230115SQ" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "4KV" },
      { label: "Ingress Protection", value: "IP20" },
      { label: "Dimension", value: "116x35x116 mm" },
      { label: "Cut Size", value: '6"' },
      { label: "Housing Type", value: "Aluminiun with PC Housing" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },
  {
    _id: "212",
    img: SQIE2022301,
    productName: "18 Watt LED Panel Light(SQ) ",
    price: "272.00",
    color: "White",
    badge: true,
    brand: "OEM",
    des: "High Output Efficiency - The High Glo Panel Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Panel Light that is made without lead and mercury.",
    cat: "Down Light",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "18 watt LED Panel Light (SQ)" },
      { label: "Item No.", value: "IE202230118RSQ" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "4KV" },
      { label: "Ingress Protection", value: "IP20" },
      { label: "Dimension", value: "220x35x220 mm" },
      { label: "Cut Size", value: '8"' },
      { label: "Housing Type", value: "Aluminiun with PC Housing" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },

  {
    _id: "213",
    img: SQ2x2IE2022301,
    productName: "2x2 36 Watt LED Panel Light ",
    price: "1121.00",
    color: "White",
    badge: true,
    brand: "OEM",
    des: "High Output Efficiency - The High Glo Panel Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Panel Light that is made without lead and mercury.",
    cat: "Down Light",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "2x2 36watt LED Panel Light " },
      { label: "Item No.", value: "IE202230336" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "4KV" },
      { label: "Ingress Protection", value: "IP20" },
      { label: "Dimension", value: "595x595 mm" },
      { label: "Cut Size", value: '2x2"' },
      { label: "Housing Type", value: "Aluminiun with PC Housing" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },

  // -----------------Concealed Light---------------

  {
    _id: "214",
    img: IE202250207,
    productName: "7 Watt LED Concealed Light ",
    price: "54.00",
    color: "White",
    badge: true,
    brand: "OEM",
    des: "High Output Efficiency - The High Glo Concealed Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Concealed Light that is made without lead and mercury.",
    cat: "Down Light",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "7 Watt LED Concealed Light " },
      { label: "Item No.", value: "IE202250207" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "4KV" },
      { label: "Ingress Protection", value: "IP20" },
      { label: "Dimension", value: "106x75 mm" },
      { label: "Cut Size", value: '3.5"' },
      { label: "Housing Type", value: "Aluminiun with PC Housing" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },

  {
    _id: "217",
    img: IE202250305,
    productName: "5 Watt LED Deep Junction Light ",
    price: "54.00",
    color: "White",
    badge: true,
    brand: "OEM",
    des: "High Output Efficiency - The High Glo Deep Junction Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Deep Junction Light that is made without lead and mercury.",
    cat: "Down Light",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "5 Watt LED Deep Junction Light " },
      { label: "Item No.", value: "IE202250305" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "4KV" },
      { label: "Ingress Protection", value: "IP20" },
      { label: "Dimension", value: "80x33 mm" },
      { label: "Cut Size", value: '40mm' },
      { label: "Housing Type", value: "Aluminiun with PC Housing" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },
  {
    _id: "219",
    img: IE202250503,
    productName: "3 Watt LED Sticker Light ",
    price: "54.00",
    color: "White",
    badge: true,
    brand: "OEM",
    des: "High Output Efficiency - The High Glo Sticker Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Sticker Light that is made without lead and mercury.",
    cat: "Down Light",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "3 Watt LED Sticker Light " },
      { label: "Item No.", value: "IE202250503" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "2.5KV" },
      { label: "Ingress Protection", value: "IP20" },
      { label: "Dimension", value: "68x15 mm" },
      { label: "Cut Size", value: 'NA' },
      { label: "Housing Type", value: "PC Housing" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },

  // =================== COB LED Light Category =================

  {
    _id: "hp1",
    img: IE202280410,
    productName: "10 Watt LED Track Light ",
    price: "590.00",
    color: "White/Black",
    badge: true,
    brand: "OEM",
    des: "High Output Efficiency - The High Glo Track Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Track Light that is made without lead and mercury.",
    cat: "Cob Light",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "10 Watt LED Track Light " },
      { label: "Item No.", value: "IE202280410" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "4KV" },
      { label: "Ingress Protection", value: "IP20" },
      { label: "Dimension", value: "60x135x255 mm" },
      { label: "Cut Size", value: 'NA' },
      { label: "Housing Type", value: "Aluminiun Housing" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },

  {
    _id: "hp2",
    img: IE202280410,
    productName: "20 Watt LED Track Light ",
    price: "805.00",
    color: "White/Black",
    badge: true,
    brand: "OEM",
    des: "High Output Efficiency - The High Glo Track Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Track Light that is made without lead and mercury.",
    cat: "Cob Light",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "20 Watt LED Track Light " },
      { label: "Item No.", value: "IE202280420" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "4KV" },
      { label: "Ingress Protection", value: "IP20" },
      { label: "Dimension", value: "70x114x235 mm" },
      { label: "Cut Size", value: 'NA' },
      { label: "Housing Type", value: "Aluminiun Housing" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },

  {
    _id: "hp3",
    img: IE202280210,
    productName: "10 Watt LED Deep COB Light ",
    price: "346.00",
    color: "White/Black",
    badge: true,
    brand: "OEM",
    des: "High Output Efficiency - The High Glo Deep COB Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Deep COB Light that is made without lead and mercury.",
    cat: "Cob Light",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "10 Watt LED Deep COB Light " },
      { label: "Item No.", value: "IE202280210" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "4KV" },
      { label: "Ingress Protection", value: "IP20" },
      { label: "Dimension", value: "70x65 mm" },
      { label: "Cut Size", value: '60mm' },
      { label: "Housing Type", value: "Aluminiun Housing" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },
  // =================== COB Cylinder Surface Light =================
  {
    _id: "ricoh1",
    img: IE202280710,
    productName: "10 Watt LED COB Cylinder Surface Light ",
    price: "346.00",
    color: "White/Black",
    badge: true,
    brand: "OEM",
    des: "High Output Efficiency - The High Glo COB Cylinder Surface Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly COB Cylinder Surface Light that is made without lead and mercury.",
    cat: "Cob Light",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "10 Watt LED COB Cylinder Surface Light " },
      { label: "Item No.", value: "IE202280710" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "4KV" },
      { label: "Ingress Protection", value: "IP20" },
      { label: "Dimension", value: "55x80 mm" },
      { label: "Cut Size", value: 'NA' },
      { label: "Housing Type", value: "Aluminiun Housing" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },
// --------------Street Light----------------
  {
    _id: "ricoh2",
    img: IE202260150,
    productName: "50 Watt LED Street Light",
    price: "590.00",
    color: "White/Black",
    badge: true,
    brand: "OEM",
    available: "15w/18w/24w/30w/50w/100w/150w/200w",
    des: "High Output Efficiency - The High Glo Street Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Street Light that is made without lead and mercury.",
    cat: "Street Light",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "50 Watt LED Street Light " },
      { label: "Item No.", value: "IE202260150" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "6KV" },
      { label: "Ingress Protection", value: "IP66" },
      { label: "Housing Type", value: "Aluminiun Die Cast" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },

  // ------------Flood Light Category--------

  {
    _id: "ricoh3",
    img: IE202270150,
    productName: "50 Watt LED Flood Light ",
    price: "590.00",
    color: "White/Black",
    badge: true,
    brand: "OEM",
    available: "15w/18w/24w/30w/50w/100w/150w/200w/400w",
    des: "High Output Efficiency - The High Glo Flood Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Flood Light that is made without lead and mercury.",
    cat: "Flood Light",
    pdf: pdf1,
    ficheTech: [
      { label: "Product Name", value: "50 Watt LED Flood Light " },
      { label: "Item No.", value: "IE202270150" },
      { label: "Power Factor ", value: "> 0.95 " },
      { label: "Inpute Voltage ", value: "AC220 - 240V, 50Hz" },
      { label: "Efficiency ", value: " > 90%" },
      { label: "Lumin Efficiency", value: " 105lm/W" },
      { label: "THD",value: "< 15% "},
      { label: "CRI", value: "> 85% " },
      { label: "Surge Protection", value: "6KV" },
      { label: "Ingress Protection", value: "IP66" },
      { label: "Housing Type", value: "Aluminiun Die Cast" },
      { label: "Colour Temp.", value: "6500k" },
    ],
  },

  // ----------Product end here--------------

  // {
  //   _id: "ricoh4",
  //   img: ricoh4,
  //   productName: "IMPRIMANTE RICOH COULEUR LASER SPC840",
  //   price: "450",
  //   color: "Blanc",
  //   badge: true,
  //   brand: "Ricoh",
  //   des: "Imprimante à Réservoir intégré HP Ink Tank 315 - Fonctions: Impression, copier, Numérisation - Format Papier: A4 - Technologie d'impression: jet d'encre thermique HP - Vitesse d'impression: Jusqu'à 19 ppm (Noir), Jusqu'à 15 ppm (couleur) - Résolution d'impression: 1200 x 1200 dpi(couleur), 4800 x 1200 dpi(noir) - Vitesse de copie(A4, ISO): 6,5 cpm Noir; 2 cpm Couleur - Vitesse de numérisation A4: Jusqu'à 21 secondes - Impression recto verso Manuelle - Formats de fichiers pour la numérisation: JPEG, TIFF, PDF, BMP, PNG - Vitesse du processeur: 360 MHz - Connectivité: USB  - Dimensions: 525 x 310 x 158 mm - Poids: 4,67 kg - Garantie: 1 an",
  //   cat: "Imprimante",
  //   pdf: pdf1,
  //   ficheTech: [
  //     { label: "DISPONIBILITÉ", value: "En stock" },
  //     { label: "gtin", value: "Z4B04A" },
  //     { label: "Marque", value: "HP" },
  //     { label: "Destockage", value: "Non" },
  //     { label: "Technologie d impression", value: "Multifonction Jet d'encre" },
  //     { label: "fonctions", value: "Multifonction" },
  //     { label: "Impression", value: "Couleur" },
  //     { label: "Mémoire", value: "Non" },
  //     { label: "FAX", value: "Non" },
  //     { label: "Fonctionnalités", value: "Impression, Copie, Numérisation" },
  //     { label: "Chargement de Documents", value: "Oui" },
  //     { label: "Connecteurs", value: "1 port USB 2.0 haut débit" },
  //     { label: "Vitesse du Processeur", value: "360 MHz" },
  //     {
  //       label: "Impression sans bordure",
  //       value: "Oui, jusqu’à 210 x 297 mm (A4)",
  //     },
  //     { label: "Vitesse d impression Noir", value: "Jusqu'à 19 ppm" },
  //     { label: "Vitesse d impression Couleur", value: "Jusqu'à 15 ppm" },
  //     {
  //       label: "Résolution d'impression Noir",
  //       value: "Jusqu'à 1 200 x 1 200 DPI",
  //     },
  //     {
  //       label: "Résolution d impression Couleur",
  //       value: "Jusqu'à 4 800 x 1 200 DPI (couleur)",
  //     },
  //     {
  //       label: "Résolution d impression",
  //       value:
  //         "Jusqu'à 1 200 x 1 200 DPI (noir), Jusqu'à 4 800 x 1 200 DPI (couleur)",
  //     },
  //     { label: "Type de Scanner", value: "Scanner à Plat" },
  //     { label: "Vitesse de numérisation", value: "Jusqu'à 21 secondes" },
  //     { label: "Résolution de Numérisation", value: "Jusqu'à 1200 x 1200 ppp" },
  //     {
  //       label: "Résolution de numérisation optimisée",
  //       value: "Jusqu’à 1200 ppp",
  //     },
  //     { label: "Recto/Verso", value: "Manuel" },
  //     { label: "Connectivite", value: "USB" },
  //     { label: "formats", value: "A4" },
  //     {
  //       label: "Premiére Page Imprimée",
  //       value:
  //         "Noir: (A4, prêt) Vitesse : 14 s - Couleur (A4, prêt) Vitesse : 18 s",
  //     },
  //     {
  //       label: "Alimentation-courant",
  //       value:
  //         "Tension d'entrée : 220 à 240 V CA (+/- 10 %), 50/60 Hz (+/- 3 Hz)",
  //     },
  //     {
  //       label: "Type de Papier pris en charge",
  //       value:
  //         "Papier ordinaire, papiers photo HP, papier mat pour brochure HP ou papier professionnel HP, papier mat pour présentation HP, papier brillant pour brochure HP ou papier professionnel HP, papiers photo jet d'encre, papiers mats jet d'encre, papiers brillant",
  //     },
  //     {
  //       label: "Consommation électrique",
  //       value:
  //         "10 W maximum, 0,07 W (Arrêt), 2,1 W (Veille), 0,88 W (Veille prolongée)",
  //     },
  //     {
  //       label: "Cycle d utilisation Mensuel",
  //       value: "A4: Jusqu'à 1 000 pages - Lettre: Jusqu'à 1 000 pages",
  //     },
  //     {
  //       label: "Alimentation papier standard",
  //       value:
  //         "Bac d'alimentation de 60 feuilles - Bac de sortie de 25 feuilles",
  //     },
  //     { label: "Ecran Tactile", value: "Non" },
  //     { label: "Type ecran", value: "7 segments et icône LCD" },
  //     {
  //       label: "Volume de Pages Mensuel Recommandé",
  //       value: "400 à 800 (Jusqu'à 1000 pages)",
  //     },
  //     { label: "Interfaces standard", value: "1x port USB 2.0 haut débit" },
  //     { label: "Résolution optique", value: "Jusqu'à 1200 x 1200 ppp" },
  //     { label: "Grammage", value: "75 g/m²" },
  //     {
  //       label: "Contenu de L'emballage",
  //       value:
  //         "HP Ink Tank 315 , cartouche d'encre noire HP GT51 (90CC), cartouche d'encre cyan HP GT52 (70CC), cartouche d'encre magenta HP GT52 (70CC), cartouche d'encre jaune HP GT52 (70CC)",
  //     },
  //     { label: "Poids", value: "4,67 kg" },
  //     { label: "Couleur", value: "Noir" },
  //     { label: "Dimensions", value: "525 x 310 x 158 mm" },
  //     { label: "Garantie", value: "1 an" },
  //   ],
  // },
  // {
  //   _id: "ricoh5",
  //   img: ricoh5,
  //   productName: "IMPRIMANTE RICOH SP4510DN (407313)",
  //   price: "450",
  //   color: "Blanc",
  //   badge: true,
  //   brand: "Ricoh",
  //   des: "Imprimante à Réservoir intégré HP Ink Tank 315 - Fonctions: Impression, copier, Numérisation - Format Papier: A4 - Technologie d'impression: jet d'encre thermique HP - Vitesse d'impression: Jusqu'à 19 ppm (Noir), Jusqu'à 15 ppm (couleur) - Résolution d'impression: 1200 x 1200 dpi(couleur), 4800 x 1200 dpi(noir) - Vitesse de copie(A4, ISO): 6,5 cpm Noir; 2 cpm Couleur - Vitesse de numérisation A4: Jusqu'à 21 secondes - Impression recto verso Manuelle - Formats de fichiers pour la numérisation: JPEG, TIFF, PDF, BMP, PNG - Vitesse du processeur: 360 MHz - Connectivité: USB  - Dimensions: 525 x 310 x 158 mm - Poids: 4,67 kg - Garantie: 1 an",
  //   cat: "Imprimante",
  //   pdf: pdf1,
  //   ficheTech: [
  //     { label: "DISPONIBILITÉ", value: "En stock" },
  //     { label: "gtin", value: "Z4B04A" },
  //     { label: "Marque", value: "HP" },
  //     { label: "Destockage", value: "Non" },
  //     { label: "Technologie d impression", value: "Multifonction Jet d'encre" },
  //     { label: "fonctions", value: "Multifonction" },
  //     { label: "Impression", value: "Couleur" },
  //     { label: "Mémoire", value: "Non" },
  //     { label: "FAX", value: "Non" },
  //     { label: "Fonctionnalités", value: "Impression, Copie, Numérisation" },
  //     { label: "Chargement de Documents", value: "Oui" },
  //     { label: "Connecteurs", value: "1 port USB 2.0 haut débit" },
  //     { label: "Vitesse du Processeur", value: "360 MHz" },
  //     {
  //       label: "Impression sans bordure",
  //       value: "Oui, jusqu’à 210 x 297 mm (A4)",
  //     },
  //     { label: "Vitesse d impression Noir", value: "Jusqu'à 19 ppm" },
  //     { label: "Vitesse d impression Couleur", value: "Jusqu'à 15 ppm" },
  //     {
  //       label: "Résolution d'impression Noir",
  //       value: "Jusqu'à 1 200 x 1 200 DPI",
  //     },
  //     {
  //       label: "Résolution d impression Couleur",
  //       value: "Jusqu'à 4 800 x 1 200 DPI (couleur)",
  //     },
  //     {
  //       label: "Résolution d impression",
  //       value:
  //         "Jusqu'à 1 200 x 1 200 DPI (noir), Jusqu'à 4 800 x 1 200 DPI (couleur)",
  //     },
  //     { label: "Type de Scanner", value: "Scanner à Plat" },
  //     { label: "Vitesse de numérisation", value: "Jusqu'à 21 secondes" },
  //     { label: "Résolution de Numérisation", value: "Jusqu'à 1200 x 1200 ppp" },
  //     {
  //       label: "Résolution de numérisation optimisée",
  //       value: "Jusqu’à 1200 ppp",
  //     },
  //     { label: "Recto/Verso", value: "Manuel" },
  //     { label: "Connectivite", value: "USB" },
  //     { label: "formats", value: "A4" },
  //     {
  //       label: "Premiére Page Imprimée",
  //       value:
  //         "Noir: (A4, prêt) Vitesse : 14 s - Couleur (A4, prêt) Vitesse : 18 s",
  //     },
  //     {
  //       label: "Alimentation-courant",
  //       value:
  //         "Tension d'entrée : 220 à 240 V CA (+/- 10 %), 50/60 Hz (+/- 3 Hz)",
  //     },
  //     {
  //       label: "Type de Papier pris en charge",
  //       value:
  //         "Papier ordinaire, papiers photo HP, papier mat pour brochure HP ou papier professionnel HP, papier mat pour présentation HP, papier brillant pour brochure HP ou papier professionnel HP, papiers photo jet d'encre, papiers mats jet d'encre, papiers brillant",
  //     },
  //     {
  //       label: "Consommation électrique",
  //       value:
  //         "10 W maximum, 0,07 W (Arrêt), 2,1 W (Veille), 0,88 W (Veille prolongée)",
  //     },
  //     {
  //       label: "Cycle d utilisation Mensuel",
  //       value: "A4: Jusqu'à 1 000 pages - Lettre: Jusqu'à 1 000 pages",
  //     },
  //     {
  //       label: "Alimentation papier standard",
  //       value:
  //         "Bac d'alimentation de 60 feuilles - Bac de sortie de 25 feuilles",
  //     },
  //     { label: "Ecran Tactile", value: "Non" },
  //     { label: "Type ecran", value: "7 segments et icône LCD" },
  //     {
  //       label: "Volume de Pages Mensuel Recommandé",
  //       value: "400 à 800 (Jusqu'à 1000 pages)",
  //     },
  //     { label: "Interfaces standard", value: "1x port USB 2.0 haut débit" },
  //     { label: "Résolution optique", value: "Jusqu'à 1200 x 1200 ppp" },
  //     { label: "Grammage", value: "75 g/m²" },
  //     {
  //       label: "Contenu de L'emballage",
  //       value:
  //         "HP Ink Tank 315 , cartouche d'encre noire HP GT51 (90CC), cartouche d'encre cyan HP GT52 (70CC), cartouche d'encre magenta HP GT52 (70CC), cartouche d'encre jaune HP GT52 (70CC)",
  //     },
  //     { label: "Poids", value: "4,67 kg" },
  //     { label: "Couleur", value: "Noir" },
  //     { label: "Dimensions", value: "525 x 310 x 158 mm" },
  //     { label: "Garantie", value: "1 an" },
  //   ],
  // },

  // // =================== imprimante ricoh =================

  // {
  //   _id: "espson1",
  //   img: espson1,
  //   productName: "IMPRIMANTE EPSON L3251 3EN1 A4 WIFI",
  //   price: "450",
  //   color: "Blanc",
  //   badge: true,
  //   brand: "Epson",
  //   des: "Imprimante à Réservoir intégré HP Ink Tank 315 - Fonctions: Impression, copier, Numérisation - Format Papier: A4 - Technologie d'impression: jet d'encre thermique HP - Vitesse d'impression: Jusqu'à 19 ppm (Noir), Jusqu'à 15 ppm (couleur) - Résolution d'impression: 1200 x 1200 dpi(couleur), 4800 x 1200 dpi(noir) - Vitesse de copie(A4, ISO): 6,5 cpm Noir; 2 cpm Couleur - Vitesse de numérisation A4: Jusqu'à 21 secondes - Impression recto verso Manuelle - Formats de fichiers pour la numérisation: JPEG, TIFF, PDF, BMP, PNG - Vitesse du processeur: 360 MHz - Connectivité: USB  - Dimensions: 525 x 310 x 158 mm - Poids: 4,67 kg - Garantie: 1 an",
  //   cat: "Imprimante",
  //   pdf: pdf1,
  //   ficheTech: [
  //     { label: "DISPONIBILITÉ", value: "En stock" },
  //     { label: "gtin", value: "Z4B04A" },
  //     { label: "Marque", value: "HP" },
  //     { label: "Destockage", value: "Non" },
  //     { label: "Technologie d impression", value: "Multifonction Jet d'encre" },
  //     { label: "fonctions", value: "Multifonction" },
  //     { label: "Impression", value: "Couleur" },
  //     { label: "Mémoire", value: "Non" },
  //     { label: "FAX", value: "Non" },
  //     { label: "Fonctionnalités", value: "Impression, Copie, Numérisation" },
  //     { label: "Chargement de Documents", value: "Oui" },
  //     { label: "Connecteurs", value: "1 port USB 2.0 haut débit" },
  //     { label: "Vitesse du Processeur", value: "360 MHz" },
  //     {
  //       label: "Impression sans bordure",
  //       value: "Oui, jusqu’à 210 x 297 mm (A4)",
  //     },
  //     { label: "Vitesse d impression Noir", value: "Jusqu'à 19 ppm" },
  //     { label: "Vitesse d impression Couleur", value: "Jusqu'à 15 ppm" },
  //     {
  //       label: "Résolution d'impression Noir",
  //       value: "Jusqu'à 1 200 x 1 200 DPI",
  //     },
  //     {
  //       label: "Résolution d impression Couleur",
  //       value: "Jusqu'à 4 800 x 1 200 DPI (couleur)",
  //     },
  //     {
  //       label: "Résolution d impression",
  //       value:
  //         "Jusqu'à 1 200 x 1 200 DPI (noir), Jusqu'à 4 800 x 1 200 DPI (couleur)",
  //     },
  //     { label: "Type de Scanner", value: "Scanner à Plat" },
  //     { label: "Vitesse de numérisation", value: "Jusqu'à 21 secondes" },
  //     { label: "Résolution de Numérisation", value: "Jusqu'à 1200 x 1200 ppp" },
  //     {
  //       label: "Résolution de numérisation optimisée",
  //       value: "Jusqu’à 1200 ppp",
  //     },
  //     { label: "Recto/Verso", value: "Manuel" },
  //     { label: "Connectivite", value: "USB" },
  //     { label: "formats", value: "A4" },
  //     {
  //       label: "Premiére Page Imprimée",
  //       value:
  //         "Noir: (A4, prêt) Vitesse : 14 s - Couleur (A4, prêt) Vitesse : 18 s",
  //     },
  //     {
  //       label: "Alimentation-courant",
  //       value:
  //         "Tension d'entrée : 220 à 240 V CA (+/- 10 %), 50/60 Hz (+/- 3 Hz)",
  //     },
  //     {
  //       label: "Type de Papier pris en charge",
  //       value:
  //         "Papier ordinaire, papiers photo HP, papier mat pour brochure HP ou papier professionnel HP, papier mat pour présentation HP, papier brillant pour brochure HP ou papier professionnel HP, papiers photo jet d'encre, papiers mats jet d'encre, papiers brillant",
  //     },
  //     {
  //       label: "Consommation électrique",
  //       value:
  //         "10 W maximum, 0,07 W (Arrêt), 2,1 W (Veille), 0,88 W (Veille prolongée)",
  //     },
  //     {
  //       label: "Cycle d utilisation Mensuel",
  //       value: "A4: Jusqu'à 1 000 pages - Lettre: Jusqu'à 1 000 pages",
  //     },
  //     {
  //       label: "Alimentation papier standard",
  //       value:
  //         "Bac d'alimentation de 60 feuilles - Bac de sortie de 25 feuilles",
  //     },
  //     { label: "Ecran Tactile", value: "Non" },
  //     { label: "Type ecran", value: "7 segments et icône LCD" },
  //     {
  //       label: "Volume de Pages Mensuel Recommandé",
  //       value: "400 à 800 (Jusqu'à 1000 pages)",
  //     },
  //     { label: "Interfaces standard", value: "1x port USB 2.0 haut débit" },
  //     { label: "Résolution optique", value: "Jusqu'à 1200 x 1200 ppp" },
  //     { label: "Grammage", value: "75 g/m²" },
  //     {
  //       label: "Contenu de L'emballage",
  //       value:
  //         "HP Ink Tank 315 , cartouche d'encre noire HP GT51 (90CC), cartouche d'encre cyan HP GT52 (70CC), cartouche d'encre magenta HP GT52 (70CC), cartouche d'encre jaune HP GT52 (70CC)",
  //     },
  //     { label: "Poids", value: "4,67 kg" },
  //     { label: "Couleur", value: "Noir" },
  //     { label: "Dimensions", value: "525 x 310 x 158 mm" },
  //     { label: "Garantie", value: "1 an" },
  //   ],
  // },

  // {
  //   _id: "espson2",
  //   img: espson2,
  //   productName: "IMPRIMANTE EPSON JET D'ENCRE L3156 COULEUR A4  WIFI",
  //   price: "450",
  //   color: "Blanc",
  //   badge: true,
  //   brand: "Epson",
  //   des: "Imprimante à Réservoir intégré HP Ink Tank 315 - Fonctions: Impression, copier, Numérisation - Format Papier: A4 - Technologie d'impression: jet d'encre thermique HP - Vitesse d'impression: Jusqu'à 19 ppm (Noir), Jusqu'à 15 ppm (couleur) - Résolution d'impression: 1200 x 1200 dpi(couleur), 4800 x 1200 dpi(noir) - Vitesse de copie(A4, ISO): 6,5 cpm Noir; 2 cpm Couleur - Vitesse de numérisation A4: Jusqu'à 21 secondes - Impression recto verso Manuelle - Formats de fichiers pour la numérisation: JPEG, TIFF, PDF, BMP, PNG - Vitesse du processeur: 360 MHz - Connectivité: USB  - Dimensions: 525 x 310 x 158 mm - Poids: 4,67 kg - Garantie: 1 an",
  //   cat: "Imprimante",
  //   pdf: pdf1,
  //   ficheTech: [
  //     { label: "DISPONIBILITÉ", value: "En stock" },
  //     { label: "gtin", value: "Z4B04A" },
  //     { label: "Marque", value: "HP" },
  //     { label: "Destockage", value: "Non" },
  //     { label: "Technologie d impression", value: "Multifonction Jet d'encre" },
  //     { label: "fonctions", value: "Multifonction" },
  //     { label: "Impression", value: "Couleur" },
  //     { label: "Mémoire", value: "Non" },
  //     { label: "FAX", value: "Non" },
  //     { label: "Fonctionnalités", value: "Impression, Copie, Numérisation" },
  //     { label: "Chargement de Documents", value: "Oui" },
  //     { label: "Connecteurs", value: "1 port USB 2.0 haut débit" },
  //     { label: "Vitesse du Processeur", value: "360 MHz" },
  //     {
  //       label: "Impression sans bordure",
  //       value: "Oui, jusqu’à 210 x 297 mm (A4)",
  //     },
  //     { label: "Vitesse d impression Noir", value: "Jusqu'à 19 ppm" },
  //     { label: "Vitesse d impression Couleur", value: "Jusqu'à 15 ppm" },
  //     {
  //       label: "Résolution d'impression Noir",
  //       value: "Jusqu'à 1 200 x 1 200 DPI",
  //     },
  //     {
  //       label: "Résolution d impression Couleur",
  //       value: "Jusqu'à 4 800 x 1 200 DPI (couleur)",
  //     },
  //     {
  //       label: "Résolution d impression",
  //       value:
  //         "Jusqu'à 1 200 x 1 200 DPI (noir), Jusqu'à 4 800 x 1 200 DPI (couleur)",
  //     },
  //     { label: "Type de Scanner", value: "Scanner à Plat" },
  //     { label: "Vitesse de numérisation", value: "Jusqu'à 21 secondes" },
  //     { label: "Résolution de Numérisation", value: "Jusqu'à 1200 x 1200 ppp" },
  //     {
  //       label: "Résolution de numérisation optimisée",
  //       value: "Jusqu’à 1200 ppp",
  //     },
  //     { label: "Recto/Verso", value: "Manuel" },
  //     { label: "Connectivite", value: "USB" },
  //     { label: "formats", value: "A4" },
  //     {
  //       label: "Premiére Page Imprimée",
  //       value:
  //         "Noir: (A4, prêt) Vitesse : 14 s - Couleur (A4, prêt) Vitesse : 18 s",
  //     },
  //     {
  //       label: "Alimentation-courant",
  //       value:
  //         "Tension d'entrée : 220 à 240 V CA (+/- 10 %), 50/60 Hz (+/- 3 Hz)",
  //     },
  //     {
  //       label: "Type de Papier pris en charge",
  //       value:
  //         "Papier ordinaire, papiers photo HP, papier mat pour brochure HP ou papier professionnel HP, papier mat pour présentation HP, papier brillant pour brochure HP ou papier professionnel HP, papiers photo jet d'encre, papiers mats jet d'encre, papiers brillant",
  //     },
  //     {
  //       label: "Consommation électrique",
  //       value:
  //         "10 W maximum, 0,07 W (Arrêt), 2,1 W (Veille), 0,88 W (Veille prolongée)",
  //     },
  //     {
  //       label: "Cycle d utilisation Mensuel",
  //       value: "A4: Jusqu'à 1 000 pages - Lettre: Jusqu'à 1 000 pages",
  //     },
  //     {
  //       label: "Alimentation papier standard",
  //       value:
  //         "Bac d'alimentation de 60 feuilles - Bac de sortie de 25 feuilles",
  //     },
  //     { label: "Ecran Tactile", value: "Non" },
  //     { label: "Type ecran", value: "7 segments et icône LCD" },
  //     {
  //       label: "Volume de Pages Mensuel Recommandé",
  //       value: "400 à 800 (Jusqu'à 1000 pages)",
  //     },
  //     { label: "Interfaces standard", value: "1x port USB 2.0 haut débit" },
  //     { label: "Résolution optique", value: "Jusqu'à 1200 x 1200 ppp" },
  //     { label: "Grammage", value: "75 g/m²" },
  //     {
  //       label: "Contenu de L'emballage",
  //       value:
  //         "HP Ink Tank 315 , cartouche d'encre noire HP GT51 (90CC), cartouche d'encre cyan HP GT52 (70CC), cartouche d'encre magenta HP GT52 (70CC), cartouche d'encre jaune HP GT52 (70CC)",
  //     },
  //     { label: "Poids", value: "4,67 kg" },
  //     { label: "Couleur", value: "Noir" },
  //     { label: "Dimensions", value: "525 x 310 x 158 mm" },
  //     { label: "Garantie", value: "1 an" },
  //   ],
  // },
];
