import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const Blog = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Blogs" prevLocation={prevLocation} />
      <div className="pb-10">
        <h1 className="max-w-[600px] text-base text-lightText mb-2">
          <span className="text-primeColor font-semibold text-lg">Inkhire</span>{" "}
          Brand is owned by INKHIRE . To  the company philosophy is the practice  of working as closely with our business partners and end users as possible, listing to there needs thoughts and wishes and understanding there markets .Knowing our business is best on newmerous individuals and endeavor to meet as many of them face - to – face as possible .
                    Building “ INKHIRE  FAMILY ”  means that all comments and feedback are welcome . The INKHIRE  brand is still experiencing  records in all sectors and our products can be found on the stage.
   Our company has a team of dodgy hirees, including members of the R&D and sales team. We have inset bacon in research and development to give new products with latest designs and technology to our deserved customers. We also provide creative design and enhance the value with our lights to your space transforming your concept in to reality.
        </h1>
        <Link to="/shop">
          <button className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300">
            Continue Shopping
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Blog;
