import React from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import {
  IE202210209,
  IE202280710,
  IE202280210,
  IE202210230,
  IE202260150,
} from "../../../assets/images/index";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";

const NewArrivals = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div className="w-full pb-16">
      <Heading heading="Latest Product" />
      <Slider {...settings}>
        <div className="px-2">
          <Product
            _id="100001"
            img={IE202210209}
            productName="9 Watt LED Bulb"
            price="30.00"
            color="white"
            badge={true}
            des="High Output Efficiency - The High Glo LED Bulb offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly bulb that is made without lead and mercury."
          />
        </div>
        <div className="px-2">
          <Product
            _id="100002"
            img={IE202280710}
            productName="10 Watt LED COB Cylinder Surface Light"
            price="346.00"
            color="Black/white"
            badge={true}
            des="High Output Efficiency - The High Glo COB Cylinder Surface Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly COB Cylinder Surface Light that is made without lead and mercury."
          />
        </div>
        <div className="px-2">
          <Product
            _id="100003"
            img={IE202280210}
            productName="10 Watt LED Deep COB Light"
            price="346.00"
            color="white/black"
            badge={false}
            des="High Output Efficiency - The High Glo Deep COB Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Deep COB Light that is made without lead and mercury."
          />
        </div>
        <div className="px-2">
          <Product
            _id="100004"
            img={IE202210230}
            productName="30 Watt LED Bulb"
            price="160.00"
            color="white"
            badge={false}
            des="High Output Efficiency - The High Glo LED Bulb offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly bulb that is made without lead and mercury."
          />
        </div>
        <div className="px-2">
          <Product
            _id="100005"
            img={IE202260150}
            productName="50 Watt LED Street Light"
            price="590.00"
            color="white/black"
            badge={false}
            des="High Output Efficiency - The High Glo Street Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Street Light that is made without lead and mercury.High Output Efficiency - The High Glo Street Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Street Light that is made without lead and mercury."
          />
        </div>
      </Slider>
    </div>
  );
};

export default NewArrivals;
