import React from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import {
  IE202250305,
  IE202260150,
  SQIE2022301,
  IE202280410,
} from "../../../assets/images/index";

const BestSellers = () => {
  return (
    <div className="w-full pb-20">
      <Heading heading="Our Bestsellers" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10">
        <Product
          _id="1011"
          img={IE202250305}
          productName="5 Watt LED Deep Junction Light"
          price="54.00"
          color="White"
          badge={true}
          des="High Output Efficiency - The High Glo Deep Junction Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Deep Junction Light that is made without lead and mercury."
        />
        <Product
          _id="1012"
          img={IE202260150}
          productName="50 Watt LED Street Light"
          price="590.00"
          color="white/black"
          badge={false}
          des="High Output Efficiency - The High Glo Street Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Street Light that is made without lead and mercury."
        />
        <Product
          _id="1013"
          img={SQIE2022301}
          productName="10 Watt LED Panel Light(SQ)"
          price="100.00"
          color="white"
          badge={true}
          des="High Output Efficiency - The High Glo Panel Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Panel Light that is made without lead and mercury."
        />
        <Product
          _id="1014"
          img={IE202280410}
          productName="10 Watt LED Track Light"
          price="590.00"
          color="Black/white"
          badge={false}
          des="High Output Efficiency - The High Glo Track Light offers high light output efficiency of 100 lumens. It spreads the luminance to broad spaces with power and efficacy.Surge Protection - The superior-quality lamp can withstand the surge of up to 4KV. It makes the product robust, long-lasting, and a perfect option for homes that have an unsteady light supply.Eco-Friendly Bulb - Take a step towards making the earth greener with this eco-friendly Track Light that is made without lead and mercury."
        />
      </div>
    </div>
  );
};

export default BestSellers;
