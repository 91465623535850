import React, { useState } from "react";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import ProductBanner from "../../components/pageProps/shopPage/ProductBanner";
import HomePagination from "../../components/pageProps/shopPage/HomePagination";

const HomeProduct = () => {
    const [itemsPerPage, setItemsPerPage] = useState(12);
    const itemsPerPageFromBanner = (itemsPerPage) => {
        setItemsPerPage(itemsPerPage);
    };

    return (
        <div className="max-w-container mx-auto px-4">
            <Breadcrumbs title="Products" />
            {/* ================= Products Start here =================== */}
            <div className="w-full h-full flex pb-20 gap-10">
      
                <div className="w-full mdl:w-[100%] lgl:w-[100%] h-full flex flex-col gap-10">
                    <ProductBanner itemsPerPageFromBanner={itemsPerPageFromBanner} />
                    <HomePagination itemsPerPage={itemsPerPage} />
                </div>
            </div>
            {/* ================= Products End here ===================== */}
        </div>
    );
};

export default HomeProduct;
