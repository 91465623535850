import React, { useEffect, useState } from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import { paginationItems } from "../../../constants";

const TrendingProducts = () => {
  // const { category } = useParams();

  const [data, setData] = useState([]);

  useEffect(() => {
    setData(paginationItems);
  }, [data]);

  // const catData = data.filter((item) => item.cat === category);
  // console.log("cat data", catData);
  return (
    <div className="w-full pb-20">
      <Heading heading="Trending Products" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-3 gap-10">
        {data.slice(21,27).map((data) => (
          <Product
            key={data._id}
            _id={data._id}
            img={data.img}
            productName={data.productName}
            price={data.price}
            color={data.color}
            badge={true}
            des={data.des}
          />
        ))}
      </div>
    </div>
  );
};

export default TrendingProducts;
